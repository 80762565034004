import React, { useEffect } from 'react';
import { ProductProvider } from './src/context/ProductContext';
import { SecondHeaderProvider } from './src/context/SecondHeaderContext';

const SetLangAttribute = () => {
  useEffect(() => {
    // Додаємо атрибут lang до <html>
    document.documentElement.lang = 'uk';
  }, []);

  return null;
};

console.log("process.env", process.env.NODE_ENV);

const isRunInProd = process.env.NODE_ENV === 'production'
// const isRunInProd = true

const GoogleRemarketingTag = () => {
  useEffect(() => {
    if (isRunInProd) {
      // Динамічне додавання Google Tag Remarketing
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10802243141';
      document.head.appendChild(scriptTag);

      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10802243141');
      `;
      document.head.appendChild(inlineScript);

      // Додаємо перевірку готовності gtag
      const checkGtagReady = setInterval(() => {
        if (window.gtag) {
          console.log('Google Ads tag successfully loaded');
          clearInterval(checkGtagReady);
        }
      }, 500);
    }
  }, []);

  return null;
};

const GoogleAnalyticsTag = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Динамічне додавання Google Analytics
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-BQ97JLR958';
      document.head.appendChild(scriptTag);

      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-BQ97JLR958');
      `;
      document.head.appendChild(inlineScript);
    }
  }, []);

  return null;
};

export const wrapRootElement = ({ element }) => (
  <ProductProvider>
    <SecondHeaderProvider>
      <GoogleRemarketingTag />
      <GoogleAnalyticsTag />
      <SetLangAttribute />
      {element}
    </SecondHeaderProvider>
  </ProductProvider>
);
