import { useEffect, useState, useRef } from "react";
import { collection, getDocs } from "firebase/firestore/lite";

const { db } = require(`@root/firebaseConfig.js`);

const useFetchProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFetched = useRef(false);

  if (process.env.NODE_ENV === "development") {
    console.log('===> run useFetchProducts');
  }

  useEffect(() => {
    if (isFetched.current) return; // Уникаємо повторного запиту

    const fetchProducts = async () => {
      try {

        if (process.env.NODE_ENV === "development") {
          console.log('===> run useFetchProducts async');
        }


        const querySnapshot = await getDocs(collection(db, "products"));
        let productsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.available && data.title) {
            return {
              id: doc.id,
              title: data.title,
              prices: data.prices,
              description: data.description,
              available: data.available,
              hot_products: data.hot_products,
              category: data.category,
              filters: data.filters,
              discount: data.discount,
              calculate: data.calculate,

              imagesName: data.images,
              thumbnailImagesName: data.thumbnail_images,
              instructionsName: data.instructions,
            };
          }
        }).filter(Boolean);

        setProducts(productsData);
        isFetched.current = true;
      } catch (fetchError) {
        console.error("Error fetching data:", fetchError);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // // Функція для оновлення продуктів без нового запиту
  // const updateProductImages = (saveImageLink) => {
  //   if (!saveImageLink || !saveImageLink.id) return;

  //   setProducts((prevProducts) =>
  //     prevProducts.map((product) =>
  //       product.id === saveImageLink.id
  //         ? { ...product, ...saveImageLink }
  //         : product
  //     )
  //   );
  // };

  return { products, loading, error };
};

export default useFetchProducts;
