import React, { createContext, useContext } from 'react';
import useFetchProducts from '@/hooks/useFetchProducts';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const { products, loading, error } = useFetchProducts();

  if (process.env.NODE_ENV === "development") {
    console.log('===> run ProductProvider');
  }

  return (
    <ProductContext.Provider value={{ products, loading, error }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};

